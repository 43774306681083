import { useMemo, useState } from "react";

import { DateTime } from "luxon";

import ErrorComponent from "../../../components/ErrorComponent";
import Table from "../../../components/Table/Table";
import {
  CustomTooltip,
  DefaultTableCell
} from "../../../styling/StyleComponents";
import { CircularProgress, styled, Typography } from "@mui/material";
import { useGetUserPresenceActivityQuery } from "common/services/UserPresenceService";
import { RowContainer } from "../../MemberDetails/StartIntake/StyledComponents";
import StyledIconButton from "../../../components/Button/StyledIconButton";
import { Diversity1 } from "@mui/icons-material";
import { blue } from "common/styling/colors";
import { useNavigate } from "react-router-dom";
import DropdownDateRangePicker from "../../../components/DropdownDateRangePicker";
import { prettyStatusString } from "common/helpers/helpers";
import {
  DateTimeColumn,
  NumberColumn,
  TextColumn
} from "../../../components/Table/helpers/TableColumns";

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 6px 0px;
`;

interface IProps {
  staffId: string;
}

const TABLE_COLUMNS = (navigate) => [
  TextColumn({
    header: "Activity",
    id: "activity",
    accessor: "status",
    getText: ({ getValue, row }) => {
      const status = getValue();
      const { visit_motivation_reason } = row.original;
      let text = prettyStatusString(status);
      if (visit_motivation_reason) {
        text += " " + prettyStatusString(visit_motivation_reason);
      }
      return text;
    }
  }),
  DateTimeColumn({
    header: "Start",
    id: "start",
    accessor: "started_at",
    format: "iso"
  }),
  DateTimeColumn({
    header: "End",
    id: "end",
    accessor: "ended_at",
    format: "iso"
  }),
  NumberColumn({
    header: "Duration (minutes)",
    id: "duration",
    accessor: "duration_minutes"
  }),
  {
    header: "Actions",
    name: "actions",
    id: "actions",
    accessor: "visit_id",
    func: "accessor",
    cell: ({ getValue, tableProps }) => {
      const visitId = getValue();

      if (!visitId) return null;

      return (
        <DefaultTableCell>
          <RowContainer>
            <CustomTooltip title={"View this task"}>
              <div>
                <StyledIconButton
                  Icon={Diversity1}
                  iconColor={blue[700]}
                  border="square"
                  onClick={(e) => {
                    navigate(`/visits/${visitId}`);
                  }}
                />
              </div>
            </CustomTooltip>
          </RowContainer>
        </DefaultTableCell>
      );
    }
  }
];

const ActivityLogTab = ({ staffId }: IProps) => {
  const navigate = useNavigate();

  const [startsAfter, setStartsAfter] = useState<DateTime>(
    DateTime.now().minus({ month: 1 }).startOf("day")
  );
  const [startsBefore, setStartsBefore] = useState<DateTime>(
    DateTime.now().endOf("day")
  );

  const { data, error, isLoading } = useGetUserPresenceActivityQuery({
    staff_id: staffId,
    start: startsAfter,
    end: startsBefore,
    order: "DESC"
  });

  const tableColumns = useMemo(() => {
    return TABLE_COLUMNS(navigate);
  }, [navigate]);

  return (
    <>
      <Row>
        <Typography flex={1} variant={"h3"} color="black">
          Activity Log
        </Typography>

        <DropdownDateRangePicker
          onEndDateChange={setStartsBefore}
          onStartDateChange={setStartsAfter}
          endDate={startsBefore}
          startDate={startsAfter}
          dateRangeDefault="30_days"
        />
      </Row>

      {isLoading && <CircularProgress />}
      {data && (
        <Table
          data={data}
          tableProps={{ navigate }}
          tableColumns={tableColumns}
          estimateRowSize={(index: number) => {
            const item = data[index];
            return item.visit_id ? 70 : 49;
          }}
        />
      )}
      <ErrorComponent error={error} />
    </>
  );
};

export default ActivityLogTab;
