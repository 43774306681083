import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  ALL_RECENT_ORDERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  NPN_LIST,
  NPN_DETAILS,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  REPORT_LIST,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  UNASSIGNED_MEMBERS,
  MEMBER_CHART,
  MEMBER_CHART_CALENDARING,
  ALL_TEAMS,
  TEAM_DETAILS,
  STAFF_DETAILS,
  MY_TODOS,
  COMPLETED_TODOS,
  COMMON_ROUTES,
  PROVIDER_SCHEDULES,
  PROVIDER_LIST,
  NURSE_SCHEDULES,
  VISITS
} from "./../RouteComponents";
import {
  PROVIDER_SCHEDULES_SIDEBAR,
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_CLINICAL_TEAMS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_NPNS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  SIDEBAR_SUPPORT,
  SIDEBAR_UNASSIGNED_MEMBERS
} from "../RouteSidebar";

import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import MemberDetailsRedirect from "../../pages/MemberDetails/MemberDetailsRedirect";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";
import MemberDetailsStartIntake from "../../pages/MemberDetails/StartIntake/MemberDetailsStartIntake";
import { CalendarIcon } from "../../assets/images/icons";
import Routes from "../Routes";

const sidebar = () => {
  const sidebarItems = [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [
        SIDEBAR_ALL_MEMBERS,
        SIDEBAR_UNASSIGNED_MEMBERS,
        SIDEBAR_INACTIVE_MEMBERS,

        SIDEBAR_ORDERS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        SIDEBAR_NPNS,
        SIDEBAR_CLINICAL_TEAMS,
        {
          itemName: "Nurse Schedules",
          itemLink: Routes.NURSE_SCHEDULES,
          ItemIcon: CalendarIcon
        }
      ]
    }
  ];

  sidebarItems.push({
    sectionName: SIDEBAR_SECTIONS.providers,
    items: [PROVIDER_SCHEDULES_SIDEBAR]
  });

  sidebarItems.push(SIDEBAR_REPORTS, SIDEBAR_SUPPORT);

  return sidebarItems;
};

const NPN_MANAGER = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: Routes.ROOT,
      components: NURSE_LIST({})
    },
    {
      path: Routes.NURSES,
      components: NURSE_LIST({})
    },
    {
      path: Routes.NURSE_REDIRECT,
      components: <MemberDetailsRedirect />
    },
    STAFF_DETAILS,
    NPN_LIST({}),
    NPN_DETAILS,
    STAFF_DETAILS,
    {
      path: Routes.ALL_MEMBERS,
      components: ALL_MEMBERS({})
    },
    {
      path: Routes.AWAITING_PROVIDER,
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" },
          { name: "patientActions", size: 75 }
        ]
      })
    },
    { path: Routes.INACTIVE_MEMBERS, components: INACTIVE_MEMBERS(true) },
    {
      path: Routes.UNASSIGNED_MEMBERS,
      components: UNASSIGNED_MEMBERS
    },
    ...MEMBER_CHART(RolesEnum.NPN_MANAGER),
    ...MEMBER_CHART_CALENDARING,
    {
      path: Routes.ASSIGNED_MEMBERS(":nurseId"),
      components: [MEMBERS_WITH_NO_ACTIVITY({}), ASSIGNED_MEMBERS({})]
    },
    {
      path: Routes.ORDERS,
      components: [<OrderDevices key="OrderDevices" />, ALL_RECENT_ORDERS()]
    },
    {
      path: Routes.DEVICES_BY_MEMBER,
      components: <OrderDevices />
    },
    {
      path: Routes.MEMBER_DETAILS("start-intake"),
      components: <MemberDetailsStartIntake hideReadings={false} />
    },
    ORDER_DETAILS(),

    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    ALL_TEAMS({
      types: [
        TeamTypeEnum.NP_NURSES,
        TeamTypeEnum.PROVIDERS,
        TeamTypeEnum.TH_NURSES,
        TeamTypeEnum.MEMBER_CARE_SPECIALISTS
      ]
    }),
    TEAM_DETAILS,
    MY_TODOS,
    COMPLETED_TODOS,
    {
      path: Routes.PROVIDER_SCHEDULES,
      components: [PROVIDER_SCHEDULES({})]
    },
    {
      path: Routes.PROVIDERS,
      components: [
        PROVIDER_LIST({
          filterByAuthenticatedUser: false
        })
      ]
    },
    NURSE_SCHEDULES,
    VISITS
  ],
  sidebar: sidebar()
};

export default NPN_MANAGER;
