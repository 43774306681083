import _objectSpread from"/codebuild/output/src2031/src/s3/00/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";import _objectWithoutProperties from"/codebuild/output/src2031/src/s3/00/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";const _excluded=["className"];import Tooltip,{tooltipClasses}from"@mui/material/Tooltip";import{styled}from"@mui/material/styles";import{blue}from"common/styling/colors";import{jsx as _jsx}from"@emotion/react/jsx-runtime";const StyledTooltip=styled(_ref=>{let{className="defaultTooltipClassname"}=_ref,props=_objectWithoutProperties(_ref,_excluded);return _jsx(Tooltip,_objectSpread(_objectSpread({},props),{},{classes:{popper:className}}));},{shouldForwardProp:prop=>prop!=="backgroundColor"&&prop!=="padding"})(_ref2=>{let{theme,backgroundColor,padding}=_ref2;return{["& .".concat(tooltipClasses.tooltip)]:{color:"white",backgroundColor:backgroundColor,width:"max-content",maxWidth:"70vw",padding:padding,borderRadius:"8px",// tbd use Paper component for box shadow? need to investigate
boxShadow:"0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 0px 16px -4px rgba(16, 24, 40, 0.15)",fontSize:"16px",fontFamily:theme.typography.fontFamily,transition:"all 0.66s ease-out"}};});/**
 *
 * @param title - the text or component(s) to display in the tooltip
 * @param placement - the position of the tooltip relative to the children
 * @param enterDelay - the delay in milliseconds before the tooltip appears
 * @param leaveDelay - the delay in milliseconds before the tooltip disappears
 * @param children - the component(s) that will trigger the tooltip - this needs to be wrapped in a div. in some cases it won't work without wrapping it in a div
 * @param backgroundColor - the background color of the tooltip
 * @param padding - the padding of the tooltip
 * @returns a React component that wraps the children with the MUI Tooltip component
 */function MUITooltip(_ref3){let{title,placement="right",enterDelay=0,leaveDelay=0,children,backgroundColor=blue[700],padding="1.3ch 1.5ch"}=_ref3;return _jsx(StyledTooltip,{placement:placement,enterDelay:enterDelay,leaveDelay:leaveDelay,title:title,backgroundColor:backgroundColor,padding:padding,children:children});}export default MUITooltip;