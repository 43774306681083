// This component will eventually replace dataFieldColumn.tsx
// https://copilotiq.atlassian.net/browse/ENG-6953

import { DateTime } from "luxon";
import { DefaultTableCell } from "../../../styling/StyleComponents";

interface ColumnProps {
  header: string;
  id: string;
  accessor: string;
}

interface DateTimeColumnProps extends ColumnProps {
  format: "iso" | "sql";
}

export const DateTimeColumn = ({
  header,
  id,
  accessor,
  format
}: DateTimeColumnProps) => {
  return {
    header,
    name: id,
    id,
    accessor,
    func: "accessor",
    sortingFn: format === "iso" ? "dateTimeSortingISO" : "dateTimeSortingSQL",
    cell: ({ getValue }) => {
      const value = getValue();
      let date;
      if (format === "iso") date = DateTime.fromISO(value);
      else date = DateTime.fromSQL(value);

      return (
        <DefaultTableCell>
          {value ? date.toFormat("MM/dd/yyyy") : "N/A"}
        </DefaultTableCell>
      );
    }
  };
};

export const NumberColumn = ({ header, id, accessor }: ColumnProps) => {
  return {
    header,
    name: id,
    id,
    accessor,
    func: "accessor",
    sortingFn: "numberSorting",
    cell: ({ getValue }) => {
      const value = getValue();

      return <DefaultTableCell>{value}</DefaultTableCell>;
    }
  };
};

interface TextColumnProps extends ColumnProps {
  getText?: ({ getValue, row }) => string;
}

export const TextColumn = ({
  header,
  id,
  accessor,
  getText
}: TextColumnProps) => {
  return {
    header,
    name: id,
    id,
    accessor,
    func: "accessor",
    cell: ({ getValue, row }) => {
      const text = getText?.({ getValue, row });

      return <DefaultTableCell>{text ?? getValue()}</DefaultTableCell>;
    }
  };
};
