import { Typography } from "@mui/material";
import { QuoteContainer, QuoteTypography } from "../../GeneralFields/Quote";

export const DeviceUsageFAQ = () => {
  return (
    <>
      <Typography variant="h6">Why does this matter?</Typography>
      <QuoteContainer>
        <QuoteTypography variant="body1">
          Consistently taking readings is a powerful tool that helps us keep
          track of your health in real time. By taking regular readings, we can:
        </QuoteTypography>
        <br />
        <QuoteTypography variant="body1">
          <b>Catch Issues Early</b> - Consistent readings allow us to identify
          any changes in your health. Early detection means we can address
          potential problems before they become serious.
        </QuoteTypography>
        <br />
        <QuoteTypography variant="body1">
          <b>Personalized Care</b> - Regular readings help us tailor your care
          plan based on your unique needs. By tracking patterns, we can adjust
          medications, suggest lifestyle changes, or recommend treatments that
          are most effective for you.
        </QuoteTypography>
        <br />
        <QuoteTypography variant="body1">
          <b>Peace of Mind</b> - Knowing your health is being monitored daily
          can provide reassurance. You'll have the confidence that we're keeping
          an eye on things and are here to help if needed.
        </QuoteTypography>
        <br />
        <QuoteTypography variant="body1">
          <b>Improved Communication</b> - With accurate and up-to-date
          information from your readings, our conversations during check-ins are
          more focused and productive. We can spend less time gathering data and
          more time discussing solutions.
        </QuoteTypography>
      </QuoteContainer>
      <Typography variant="h6">Tips</Typography>
      <QuoteContainer>
        <QuoteTypography variant="body1">
        <b>Set a Reminder</b> - Try setting an alarm on your phone or writing it on your calendar.
        </QuoteTypography>
        <br />
        <QuoteTypography variant="body1">
        <b>Choose a Routine</b> - Take your readings at the same time every day, such as in the morning before breakfast.
        </QuoteTypography>
        <br />
        <QuoteTypography variant="body1">
        <b>Keep Your Device Handy</b> - Make sure your device is in a place where you'll see it regularly.
        </QuoteTypography>
        <br />
        <QuoteTypography variant="body1">
        <b>Ask for Help</b> - If you're having trouble with the device, let us know. We're here to assist you.
        </QuoteTypography>
      </QuoteContainer>
    </>
  );
};
